import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Link from 'components/ui/Link';
import intlShape from 'utils/shapes/intlShape';

import _get from 'lodash/get';
import trackYouleadVirtualPageView from 'utils/YouLead/TrackYouleadVirtualPageView';
import {
  OffersListitemWrapper, Image, ItemHeader, ItemDescription, ItemInfo, ItemInfoTitle, ItemInfoValue,
} from './OffersListItem.styles';
import { OffersListShapes } from './OffersListItem.shapes';
import messages from './OffersListItem.messages';
import { VisibilityContainer } from '../../../../../../../utils/styles';
import config from '../../../../../../../config';


const OffersListItem = ({ item, intl, isPromotion }) => {
  const openExternalWindow = () => {
    if (item.id) {
      const windowUrl = _get(config.investmentExternalWindow, item.id, false);

      if (windowUrl && item.fieldInvestmentUpcoming) {
        window.open(windowUrl, 'Start_external', `width=${window.screen.availWidth}, height=${window.screen.availHeight}, fullscreen=1`);
      }
    }
  };

  const openExternalTab = () => {
    if (item.id && item.fieldInvestmentExternalUrl) {
      window.open(item.fieldInvestmentExternalUrl, '_blank');
    }
  };

  const handleClick = () => {
    openExternalWindow();
    openExternalTab();
    trackYouleadVirtualPageView(`${item.id}/opis`);
  };

  const getRoute = () => {
    if (item.fieldInvestmentExternalUrl) {
      return '/';
    }

    return isPromotion ? 'promotionsList' : item.goTo.route;
  };

  return (
    <OffersListitemWrapper>
      <Link
        route={getRoute()}
        params={isPromotion ? { page: 1 } : item.goTo.params}
        onClick={handleClick}
      >
        <Image src={item.image} alt="slide" isPromotion={isPromotion} />
        <ItemHeader>
          {item.title}
        </ItemHeader>
        <ItemDescription>
          {item.description && item.description.address
          && intl.formatMessage(messages.checkAvailableLocals) !== item.description.address
          && (
          <ItemInfo>
            <ItemInfoTitle>
              {intl.formatMessage(messages.address)}
            </ItemInfoTitle>
            <ItemInfoValue>
              &nbsp;{item.description.address}
            </ItemInfoValue>
          </ItemInfo>)}
          {item.description && item.description.address
          && intl.formatMessage(messages.checkAvailableLocals) === item.description.address
          && (
          <ItemInfo>
            <ItemInfoTitle>
              {item.description.address}
            </ItemInfoTitle>
          </ItemInfo>)}
          <VisibilityContainer sm xs md lg>
            {
                item.description && item.description.realizationDate
                  ? (
                    <ItemInfo>
                      <ItemInfoTitle>
                        {intl.formatMessage(messages.realizationDate)}
                      </ItemInfoTitle>
                      <ItemInfoValue>
                        &nbsp;{item.description.realizationDate}
                      </ItemInfoValue>
                    </ItemInfo>
                  )
                  : null
              }
          </VisibilityContainer>
        </ItemDescription>
      </Link>
    </OffersListitemWrapper>
  );
};

OffersListItem.propTypes = {
  intl: intlShape.isRequired,
  isPromotion: PropTypes.bool,
  item: OffersListShapes,
};

OffersListItem.defaultProps = {
  item: {},
  isPromotion: false,
};

export default injectIntl(OffersListItem);

import styled from 'styled-components';

const OffersListitemWrapper = styled.li`
  margin: 5px 10px 0 0;
  flex-basis: calc(33.333% - 10px);
  list-style: none;
  height: min-content;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 5px auto 0;
    max-width: 380px;
    flex-basis: 100%;
  }

  & > div > a {
    display: flex;
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: right;
`;

const ItemHeader = styled.h3`
  font-size: 19px;
  line-height: 23px;
  margin: 24px 0 8px 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.primary3Color};
  letter-spacing: 1px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const LineCharacter = styled.span`
  margin: 0 0px;
`;

const ItemDescription = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 2.3;
  margin-bottom: 55px;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 19px;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    justify-content: center;
  }
`;

const ItemInfoTitle = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.primary5Color};
  margin: 0;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 13px;
  }
`;

const ItemInfoValue = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.primary5Color};
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 13px;
  }
`;

export {
  OffersListitemWrapper, Image, ItemHeader, ItemDescription, ItemInfo, ItemInfoTitle, ItemInfoValue,
};
